<template>
  <MainLayout
    class="main-layout--chat">
    <router-view name="list"></router-view>
    <router-view name="messages"></router-view>
  </MainLayout>
</template>

<script>

import MainLayout     from '../../../components/layout/MainLayout/l-MainLayout.vue';


export default {
  name: 'p-Chat',
  components: {
    MainLayout,
  },
}

</script>
